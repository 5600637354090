import { generateAkamaiUrl } from '../helpers/Akamai'


const config = require('../configuration')


export const shareLinksTransformer = (params, t) => {
  const links = []

  let url = params?.url ?? null
  const title = params?.title ?? ''
  const sharing = params?.sharing ?? true

  if (url && sharing) {
    url = url.indexOf('/') === 0 ? `${config.app.APP_URL}${url}` : url

    links.push({
      linkProps: {
        href: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
        target: 'blank',
      },
      icon: 'Facebook',
      text: t('shareFacebook'),
    })

    links.push({
      linkProps: {
        href: `https://twitter.com/intent/tweet/?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`,
        target: 'blank',
      },
      icon: 'Twitter',
      text: t('shareTwitter'),
    })

    const mailToSubject = (title === '') ? t('siteTitle') : `${title} - ${t('siteTitle')}`
    const mailToBody = params?.mailToBody ?? t('shareMailBody')

    // console.log('mailToBody', mailToBody)

    links.push({
      linkProps: {
        href: `mailto:?subject=${encodeURIComponent(mailToSubject)}&body=${encodeURIComponent(`${mailToBody} : ${url}`)}`,
        target: 'blank',
      },
      icon: 'Mail',
      text: t('shareMail'),
    })
  }

  const printJs = params?.printJs ?? true

  if (printJs) {
    links.push({
      linkProps: {
        href: '',
        onClick: () => {
          if (typeof window !== 'undefined') {
            window.print()
          }
        },
      },
      icon: 'Print',
      text: t('print'),
    })
  }

  const downloadPdf = params?.downloadPdf ?? true

  if (downloadPdf) {
    links.push({
      linkProps: {
        onClick: () => {
          if (typeof window !== 'undefined') {
            const pdfUrl = `${window.location.origin}/pdf/${Buffer.from(window.location.pathname + window.location.hash)
                .toString('base64')}`
            const element = document.createElement('a')

            element.setAttribute('href', pdfUrl)
            element.setAttribute('download', `${title}.pdf`)
            element.style.display = 'none'
            document.body.appendChild(element)
            element.click()
            document.body.removeChild(element)
          }
        },
      },
      icon: 'Download',
      text: t('download'),
    })
  }

  return {
    links,
  }
}

export const richTextToBasicHtmlTransformer = (richText) => {
  if (!richText) {
    return ''
  }

  let basicHtml = richText.replaceAll('<p>', '')

  basicHtml = basicHtml.split('</p>')
      .filter(Boolean)

  return basicHtml.join('<br />')
}

export const videoCustomTransformer = (video, useAlternateCover = false) => {
  let logo = null
  const videoUrl = video?.entity?.fieldUrlVideo ?? ''
  const posterUrl = generateAkamaiUrl(
      useAlternateCover
          ? (video?.entity?.fieldImageSquare?.entity?.url ?? video?.entity?.fieldImage?.entity?.url)
          : (video?.entity?.fieldImage?.entity?.url)
  )

  if (video?.entity?.fieldLogo?.entity?.url) {
    logo = {
      src: generateAkamaiUrl(video?.entity?.fieldLogo?.entity?.url ?? ''),
      alt: '',
    }
  }

  const tracks = []

  if (video?.entity?.fieldSubtitles?.entity?.url) {
    tracks.push({
      kind: 'captions',
      label: 'Français',
      srclang: 'fr',
      src: generateAkamaiUrl(video?.entity?.fieldSubtitles?.entity?.url),
    })
  }

  let linkTranscription = null

  if (video?.entity?.fieldTranscription?.entity?.entityUrl?.path) {
    linkTranscription = {
      href: generateAkamaiUrl(video?.entity?.fieldTranscription?.entity?.entityUrl?.path ?? ''),
      target: '_blank',
    }
  }

  return {
    title: video?.entity?.title ?? '',
    path: video?.entity?.entityUrl?.path ?? '',
    theme: video?.entity?.fieldTheme ?? '',
    logo,
    duration: video?.entity?.fieldDuration ?? 0,
    videoSource: {
      type: 'video',
      src: videoUrl ? generateAkamaiUrl(videoUrl) : '',
      poster: posterUrl ?? '',
      sources: [
        {
          src: generateAkamaiUrl(video?.entity?.fieldUrlVideo ?? ''),
          type: 'video/mp4',
        },
      ],
      tracks,
    },
    linkTranscription,
  }
}

export const videoCustomPlaylistTransformer = (fieldVideoItem, useAlternateCover = false) => {
  const typename = fieldVideoItem?.entity?.__typename ?? null

  if (!typename) {
    return []
  }

  if (typename === 'NodeVideo') {
    return [videoCustomTransformer(fieldVideoItem, useAlternateCover)]
  }
  if (typename === 'NodeVideosPlaylist') {
    const videos = fieldVideoItem?.entity?.fieldVideos ?? []

    return videos.map((video) => videoCustomTransformer(video, useAlternateCover))
  }

  return []
}

export const tcPagenameTransformer = (metas, defaultTitle = '') => {
  let pagename = ''

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].key === 'shortlink' && (metas[i].value === `${config.api.API_URL}/fr` || metas[i].value === `${config.api.API_URL.replace('https://', 'http://')}/fr` || metas[i].value === `${config.app.APP_URL}/fr`)) {
      pagename = 'Accueil 1000 premiers jours'
      break
    }
    if (metas[i].key === 'title') {
      pagename = metas[i].value
      break
    }
  }

  if (pagename === '' && defaultTitle) {
    pagename = defaultTitle
  }

  if (pagename === '') {
    pagename = '1000 premiers jours'
  } else {
    pagename = pagename.replace(' - 1000 premiers jours', '')
    pagename = pagename.replace('| ', ' ')
  }

  return pagename
}
